import React from "react"
import { Link } from "react-router-dom"
import styles from "./About.module.css"
import Navmenu from "../../components/Navmenu/Navmenu";
import { FaLock } from "react-icons/fa"
import { BsFillStarFill } from "react-icons/bs"
import { FaShareSquare } from "react-icons/fa"
import { FaUpload } from "react-icons/fa"
import { AiFillSetting } from "react-icons/ai"
import { MdAccountCircle } from "react-icons/md"
import { ImZoomIn } from "react-icons/im"
import { FaGlobe } from "react-icons/fa"
import { FaAdjust } from "react-icons/fa"
import { FaGooglePlay } from "react-icons/fa"
import { BsApple } from "react-icons/bs"



const WHY_US = [
    {
        id: "0",
        value: "We deliver the complete project in which the design and construction services are contracted.",
        heading: "01",
        discription: "Preliminary Study ",
    },
    {
        id: "1",
        value: "We undertake construction management professional service that uses specialized, project management techniques to oversee the planning, design,and construction of a project, from its beginning to its end.",
        heading: "02",
        discription: "PMC"

    },
    {
        id: "2",
        value: "We take the responsibility of clientsspace aesthetic, also reviewing theclients space as per the requirement.",
        heading: "03",
        discription: "Design & Consultation",

    },
]
const Proces = [
    {
        id: "0",
        value: <BsFillStarFill color="black" size={30} />,
        heading: "01",
        discription: "Preliminary Study ",
    },
    {
        id: "1",
        value: <FaLock color="black" size={30} />,
        heading: "02",
        discription: "Concept Development "

    },
    {
        id: "2",
        value: <AiFillSetting color="black" size={30} />,
        heading: "03",
        discription: "Schematic Design",

    },
    {
        id: "3",
        value: <MdAccountCircle color="black" size={30} />,
        heading: "04",
        discription: " Authority Approval"

    },
    {
        id: "4",
        value: <FaShareSquare color="black" size={30} />,
        heading: "05",
        discription: "Construction Doc's",

    },
    {
        id: "5",
        value: <FaUpload color="black" size={30} />,
        heading: "06",
        discription: "Material Procurement",

    },
    {
        id: "6",
        value: <ImZoomIn color="black" size={30} />,
        heading: "07",
        discription:
            "Construction",
    },
    {
        id: "7",
        value: <FaGlobe color="black" size={30} />,
        heading: "08",
        discription:
            "Closeout & Service  ",
    },

]
const Strength = () => {
    return (

        <div >
            <Navmenu />
            <div className={styles.padding}>
                <div>
                    <div className={styles.headsabout}>About Us:</div>

                    <p className={styles.liabout}>Frillory is a full service boutique design firm focused on creating unique and personal spaces.</p>
                    <p className={styles.liabout}>Our design philosophy rests on finding the perfect balance of function, aesthetic and uniqueness to create spaces that are visually balanced and feel calm and collected.</p>
                    <p className={styles.liabout}>Our journey began in 2022 in Bangalore, India. We can't wait to be part of your story and bring your design aesthetic to reality!</p>
                </div>



                <div>
                    <div className={styles.headsaboutt}>Our Strength:</div>
                    <ul>
                        <p className={styles.liabout}><li>We strive to give back to the environment through design practices.</li></p>
                        <p className={styles.liabout}><li>We encourage diversity of culture, language, and expertise in allied fields of design.</li></p>
                        <p className={styles.liabout}><li>We believe in learning, sharing, and improvement for continuous growth.</li></p>
                        <p className={styles.liabout}><li>We are always working towards meeting our clients’ expectations, creatingdesigns that reflect the brand’s personality, and delivering on time.</li></p>

                    </ul>
                </div>

                <div>
                    <div className={styles.headsaboutt}>What We Do</div>
                    <div className={styles.whyUsGrid}>
                        {WHY_US.map((item, index) => (
                            <div key={index} className={styles.whyUsCard}>
                                <div className={styles.buttonbox}>

                                    <div className={styles.buttonbottom}>
                                        <div className={styles.name}>{item.heading}</div>
                                        <div className={styles.year}> {item.discription}</div>

                                    </div>
                                    <div className={styles.buttontop}>
                                        {item.value}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>



                </div>


                <div>
                    <div className={styles.headsaboutt}>Our Process</div>
                    <div className={styles.processGrid}>
                        {Proces.map((item, index) => (
                            <div key={index} className={styles.processCard}>
                                <div className={styles.processbuttonbox}>

                                    <div className={styles.processbuttonbottom}>
                                        <div className={styles.processname}>{item.heading}</div>
                                        <div className={styles.processyear}> {item.discription}</div>

                                    </div>
                                    <div className={styles.processbuttontop}>
                                        {item.value}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>



<div>

<div className={styles.HomeLandingBoxvalue}>
                <div className={styles.HomeLandingMainBoxvalue}>
                    <div className={styles.headsvalue}>Our</div>
                    <div className={styles.headsvalue}>Values</div>

                    <p className={styles.HomePageTextTwovalue}>
                        "To make the world a better place"
                    </p>

                </div>
                <div className={styles.HomeLandingimgBoxvalue}>
                    <div className={styles.missionvalue}>Vision</div>
                    <div className={styles.missiononevalue}>
                        <div className={styles.missiontwovalue}>
                            Design & Build to
                            Perfection.
                        </div>
                        <div className={styles.missionthreevalue}>
                            <h1 className={styles.missionnvalue}>Mission</h1>
                            <p className={styles.achivevalue}>To Achieve beyond possibilities and beyond expectations</p>
                        </div>

                    </div>
                </div>
            </div>  
</div>



            </div>
        </div>

    )
}
export default Strength