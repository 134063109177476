import React from "react"
import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
       
      <img
          src="images/projectone.jpg"
          style={{
            width: "100%", height: "100%", padding: 0,
          }}
          alt="Computer" /> 
      
      </Carousel.Item>
      <Carousel.Item>
      <img
      src="images/projecttwo.jpg"
      style={{
        width: "100%", height: "50%", padding: 0,
      }}
      alt="Computer" />

        
      </Carousel.Item>
      <Carousel.Item>
      <img
    src="images/projectthree.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>
        
      </Carousel.Item>
      <Carousel.Item>
       
       <img
           src="images/projectfour.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>
       <Carousel.Item>
       
       <img
           src="images/projectfive.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/projectsix.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/projectseven.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>


       <Carousel.Item>
       
       <img
           src="images/projecteight.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>

      
    </Carousel>
  );
}

export default UncontrolledExample;