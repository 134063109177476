import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./Prestige.module.css";
import { useState } from "react";
import { ImageOverlay } from 'react-image-overlay-effect'
import { Link } from "react-router-dom"
import Prestigeslide from "../Prestige/Prestigeslide"

const showcase = [
  {
    id: "0",
    heading: <img
      src="images/Prestige/prestigeflatbedroom.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="prestigeflatbedroom" /> },
  {
    id: "1",
    heading:<img
    src="images/Prestige/prestigeflatbedroomtwo.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflatbedroomtwo"
/>},
 

 {
    id: "2",
    heading:<img
    src="images/Prestige/prestigeflatdining.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflatdining"
/>},
  {
    id: "3",
    heading:<img
    src="images/Prestige/prestigeflatdoor.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflatdoor"
/>},
 
 {
    id: "4",
    heading:<img
    src="images/Prestige/prestigeflathall.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflathall"
/>},

{
    id: "5",
    heading:<img
    src="images/Prestige/prestigeflathalltwo.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflathalltwo"
/>},
   
  
]

const Prestige = () => {
  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  return (
    <div >
      <Navmenu />
      <div >
      <div className={styles.heads}>Aurora - Prestige song of the south
</div> 
      <div className={styles.sliding}><Prestigeslide/></div>
        <div className={styles.showGrid}>
          {showcase.slice(0, visible).map((item, index) => (
            <div key={index} className={styles.showCard}>
           {item.heading}  
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {visible < showcase.length &&
          <button className={styles.showmore} onClick={showMoreItems} >Show More</button>
        }
      </div>
    </div>
  )
}
export default Prestige