import React, {  useState } from "react";
import styles from "./Navmenu.module.css";
import { Link } from "react-router-dom"

const Navmenu  = () => {
  const [myProfession, setMyProfession] = useState("Projects");
  return (
    <div className={styles.Mainbox}>
       <div className={styles.imgbox}>
           <img
            style={{
              width: "150px",
              height: "118px",
              alignSelf: "flex-start",
              marginTop:"10px",
            }}
            alt="logo.png"
            src={"./images/indexf.jpg"}
          />
          </div>
           <div className={styles.menubox}>
           <Link to="/" className={styles.linkItem}>Projects </Link>
           <Link to="/About" className={styles.linkItem}>About </Link>
           <Link to="/Contact" className={styles.linkItem}>Contact </Link>
          
           </div>        
 </div>
  )
}
export default Navmenu