import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./Press.module.css"
import { Link } from "react-router-dom";

const PACKAGES = [
  {
    id: "0",
    one: <p>The work of Bhumiputra Architecture and its principal architect, Alok Shetty has been exhibited and published extensively by several national & international publications,</p>,
    two: <p>(2021) Bhumiputra wins the masterplanning, design and conservation of Jog Falls, Karnataka</p>,
    three: <p>(2021) Bhumiputra wins the masterplanning and conservation of UNESCO World Heritage site: Sun Temple, Konark</p>,
    four: <p>(2020) Bhumiputra wins national competition to design the prestigious Shree Jagannatha Temple Heritage Corridor, Puri</p>,
    five: <p>(2019) Inspire Institute of Sport wins National Award for Sports Infrastructural Design & Development</p>,
    six: <p>(2019) Chote Kadam Project Finalist: Social Impact Category at Forbes Design Awards</p>,
    eight: <p>(2018) World Economic Forum: Young Global Leader</p>,
    nine: <p>(2018) VERVE: Rooted in Reason</p>,
    eleven: <p>(2017) VICE China: Future Home Series Documentary</p>,
    twelve: <p>(2017) Make in India: Young India's Rising Stars</p>,
    thirteen: <p>(2017) Real Leaders: Top 100 Visionary Leaders</p>,
    fourteen: <p>(2016) Forbes Asia: Cover Story: 30 under 30 list of Young Achievers</p>,
    fifteen: <p>(2015) Architect & Interiors: Ranked #1 on Annual list of Top 50 Young Architects</p>,
    sixteen: <p>(2015) Elle: 25 people to watch</p>,
    seventeen: <p>(2014) TIME: International Next Generation Leaders</p>,
    eighteen: <p>(2014) Complex: Young Architects Transforming Urban Skylines Around the World</p>,
    nineteen: <p>(2014) Forbes India: 30 under 30 list of Young Achievers</p>,
    twenty: <p>(2013) National Geographic: India's Future Leaders</p>,
    twentyone: <p>(2013) TEDxMUSCAT</p>,
    twentytwo: <p>(2012) POOL: India's future designers</p>,
    twentythree: <p>(2012) INK Fellow</p>,
    twentyfour: <p>(2011) TEDxNEWYORK</p>,
  },

]

const Press = () => {
  return (
    <div>
      <Navmenu />
      <div className={styles.press}>
        <div className={styles.packagesGrid}>
          {PACKAGES.map((item, index) => (
            <div key={item.id} className={styles.packageCard}>
              <div className={styles.packageNameHead}>
                <div className={styles.name}>
                  <h2 className={styles.packageName}>{item.type}</h2>
                </div>
              </div>
              <div className={styles.packageDescription}>
                <div className={styles.linkItem}>
                  <p>{item.one}</p>
                  <p>{item.two}</p>
                  <p>{item.three}</p>
                  <p>{item.four}</p>
                  <p>{item.five}</p>
                  <p>{item.six}</p>
                  <p>{item.seven}</p>
                  <p>{item.eight}</p>
                  <p>{item.eleven}</p>
                  <p>{item.twelve}</p>
                  <p>{item.thirteen}</p>
                  <p>{item.fourteen}</p>
                  <p>{item.fifteen}</p>
                  <p>{item.sixteen}</p>
                  <p>{item.seventeen}</p>
                  <p>{item.eighteen}</p>
                  <p>{item.nineteen}</p>
                  <p>{item.twenty}</p>
                  <p>{item.twentyone}</p>
                  <p>{item.twentytwo}</p>
                  <p>{item.twentythree}</p>
                  <p>{item.twentyfour}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.imgmainbox}>
          <div className={styles.imgright}>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressone.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressthree.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressfive.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/presssix.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressseven.jpg"}
              />

            </div>
          </div>
          <div className={styles.imgright}>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/presstwo.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressfour.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                alt="pone.jpg"
                src={"./images/presseight.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressnine.jpg"}
              />
            </div>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pressten.jpg"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Press