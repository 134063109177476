import React from "react"
import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
       
      <img
          src="images/projectnine.jpg"
          style={{
            width: "100%", height: "100%", padding: 0,
          }}
          alt="Computer" /> 
      
      </Carousel.Item>
      <Carousel.Item>
      <img
      src="images/projectten.jpg"
      style={{
        width: "100%", height: "50%", padding: 0,
      }}
      alt="Computer" />

       
      </Carousel.Item>
      <Carousel.Item>
      <img
    src="images/project11.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>
        
      </Carousel.Item>
      <Carousel.Item>
       
       <img
           src="images/project12.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 

       </Carousel.Item>
       <Carousel.Item>
       
       <img
           src="images/project13.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
         
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/project14.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
         
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/project15.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>


       <Carousel.Item>
       
       <img
           src="images/project16.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
       
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/project17.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="Computer" /> 
        
       </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;