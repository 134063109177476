import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./TataPromont.module.css";
import { useState } from "react";
import { ImageOverlay } from 'react-image-overlay-effect'
import { Link } from "react-router-dom"
import Pageslide from "../Pageslide/Pageslide"

const showcase = [
    {
        id: "0",
        heading: <div className={styles.bounceInLeft}><img
          src="images/projectnine.jpg"
          style={{
            width: "100%", height: "100%", padding: 0,
          }}
          alt="Computer" /> </div>},
  {
    id: "0",
    heading: <img
      src="images/projectten.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="Computer" /> },
  {
    id: "0",
    heading:<img
    src="images/project11.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
 

 {
    id: "0",
    heading:<img
    src="images/project12.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
  {
    id: "0",
    heading:<img
    src="images/project13.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
 
 {
    id: "0",
    heading:<img
    src="images/project14.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},

{
    id: "0",
    heading:<img
    src="images/project15.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
   {
    id: "0",
    heading:<img
    src="images/project16.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
   {
    id: "0",
    heading:<img
    src="images/project17.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
  
]

const Home = () => {
  const [visible, setVisible] = useState(10);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 10);
  };

  return (
    <div >
      <Navmenu />
    
      <div >
      <div className={styles.heads}>TATA PROMONT</div> 
      <div className={styles.sliding}><Pageslide/></div>
        <div className={styles.showGrid}>
          {showcase.slice(0, visible).map((item, index) => (
            <div key={index} className={styles.showCard}>
           {item.heading}  
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {visible < showcase.length &&
          <button className={styles.showmore} onClick={showMoreItems} >Show More</button>
        }
      </div>
    </div>
  )
}
export default Home