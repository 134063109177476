import React from "react"
import { MdEmail } from "react-icons/md"
import { AiOutlinePhone } from "react-icons/ai"
import { TfiWorld } from "react-icons/tfi"
import styles from "./Contact.module.css";
import { Link } from "react-router-dom"
import Navmenu from "../../components/Navmenu/Navmenu";


const Contact = () => {
    return (

        <div >
          <Navmenu/>
            <div className={styles.Contact}>
                <div className={styles.HomeLandingBox}>
                    <div className={styles.HomeLandingimgBox}>
                        <img
                            style={{
                                width: "80%",
                                height: "90%",
                                alignSelf: "flex-start",
                                marginTop: "5px",
                            }}
                            alt="pone.jpg"
                            src={"./images/contact.jpg"}
                        />


                    </div>


                    <div className={styles.HomeLandingMainBox}>
                        <div className={styles.mainBox}>
                            <div className={styles.heads}>Contact Us:</div>

                            <div className={styles.link}>

                                <div className={styles.linkItem} onClick={() => window.open("tel: 9035710288", "_blank")}>{" "}
                                    <AiOutlinePhone color="black" size={15} />  9035710288</div>

                                <div className={styles.linkItem} onClick={() => window.open("mailto:design@frillory.com", "_blank")}>{" "}
                                    <MdEmail color="black" size={15} />  design@frillory.com</div>


                                <div className={styles.linkItem} onClick={() => window.open("www.frillory.com", "_blank")}>
                                    <TfiWorld color="black" size={15} />  www.frillory.com</div>


                            </div>





                        </div>

                    </div>

                </div>
                <div className={styles.headss}>FRILLORY</div>
                <b>   <p className={styles.achive}>© Copyright Frillory 2022</p></b> 
                        <p  className={styles.achive}>The copyright in this work is vested in Frillory and is issued in confidence for the purpose for
which it is supplied. It must not be reproduced in whole or in part or used for tendering or
manufacturing purposes except under an agreement or with the consent in writing of Frillory and
then only on the condition that this notice is included in any such reproduction. No information as
to the contents or the subject matter of this document or any part thereof arising directly or
indirectly there from shall be given orally or in writing or communicated in any manner
whatsoever to any third party being an individual firm or employee thereof without the prior
consent in writing of Frillory</p>


            </div>
        </div>

    )
}
export default Contact