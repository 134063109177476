import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./Home.module.css";
import { useState } from "react";
import { ImageOverlay } from 'react-image-overlay-effect'
import { Link } from "react-router-dom"

const showcase = [
  {
    id: "0",
    heading: <Link to="/AdarshPalm"><ImageOverlay
      src="images/projectone.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="Computer"
      description={<p className={styles.imgeffect}>ADARSH PALM RETREAT </p>}
    
    /></Link>
   ,
  },
  {
    id: "1",
    heading:<Link to="/TataPromont"><ImageOverlay
    src="images/projecttwo.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="Computer"
      description={<p className={styles.imgeffect}>TATA PROMONT</p>}
    /></Link>,

  },
  {
    id: "2",
    heading:<Link to="/Prestige"><ImageOverlay
    src="images/Prestige/prestigeflathalltwo.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="Prestige"
      description={<p className={styles.imgeffect}>Aurora - Prestige song of the south
      </p>}
    /></Link>,

  },

  // },
  // {
  //   id: "0",
  //   heading:<ImageOverlay
  //   src="images/projectfour.jpg"
  //     style={{
  //       width: "100%", height: "100%", padding: 0,
  //     }}
  //     alt="Computer"
  //     description={<p className={styles.imgeffect}>ADARSH PALM RETREAT </p>}
  //   />,

  // },
 
]

const Home = () => {
  const [visible, setVisible] = useState(10);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 10);
  };

  return (
    <div >
      <Navmenu />
      <div >
        <div className={styles.showGrid}>
          {showcase.slice(0, visible).map((item, index) => (
            <div key={index} className={styles.showCard}>
           {item.heading}  
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {visible < showcase.length &&
          <button className={styles.showmore} onClick={showMoreItems} >Show More</button>
        }
      </div>
    </div>
  )
}
export default Home