import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom"

import Home from './screens/Home/Home';
import Practice from './screens/Practice/Practice';
import Contact from './screens/Contact/Contact';
import Press from './screens/Press/Press';
import AdarshPalm from './screens/AdarshPalm/AdarshPalm';
import Prestige from './screens/Prestige/Prestige';
import TataPromont from './screens/TataPromont/TataPromont';
import About from './screens/About/About';
function App() {
  return (
    <BrowserRouter>
   
        <Routes>
          <Route exact path="" element={<Home />} />
          <Route exact path="/Practice" element={<Practice />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Press" element={<Press />} />     
          <Route exact path="/AdarshPalm" element={<AdarshPalm />} />    
          <Route exact path="/TataPromont" element={<TataPromont />} />   
          <Route exact path="/Prestige" element={<Prestige />} />   
          <Route exact path="/About" element={<About />} />   

        </Routes>
     
    </BrowserRouter>
  )
}

export default App
