import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./Practice.module.css";
import { Link } from "react-router-dom"


const Practice = () => {
  return (

    <div >
      <Navmenu />
      <div className={styles.Practice}>
        <p style={{ fontSize: "12px" }}>At Bhumiputra, we strive to bridge the demands of artistic endeavor,
          environmental responsibility, functional pragmatics, and financial
          considerations. As a multidisciplinary firm, we believe that innovations
          discovered through the process of design and construction can be applied
          to projects of any scale, use, or purpose.</p>
        <p style={{ fontSize: "12px" }}>Over the past few years, we have cultivated a team that strives to deliver
          the highest level of project management, service, and design. Our approach
          is client and site specific, and questions conventional assumptions.
          A collaborative process with clients, consultants, fabricators, and builders
          allows an open exploration and enables a clear understanding of appropriate solutions.</p>

        <div className={styles.practiceimgbox}>
          <div className={styles.practiceleft}>
            <div> <img
              style={{
                width: "100%",
                height: "100%",
                PaddingBottom: "5px",
                alignSelf: "flex-start",
              }}
              alt="pone.jpg"
              src={"./images/ptwo.jpg"}
            />
            </div>
          </div>
          <div className={styles.practiceright}>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pone.jpg"}
              /></div>
          </div>
        </div>
        <div className={styles.practiceimgbox}>
          <div className={styles.practiceleft}>
            <div>  <img
              style={{
                width: "100%",
                height: "100%",
                alignSelf: "flex-start",
                marginTop: "5px",
              }}
              alt="pone.jpg"
              src={"./images/pthree.jpg"}
            />
            </div>
          </div>
          <div className={styles.practiceright}>
            <div>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  alignSelf: "flex-start",
                  marginTop: "5px",
                  marginInlineStart: "10px",
                }}
                alt="pone.jpg"
                src={"./images/pfour.jpg"}
              />
            </div>
          </div>
        </div>

        <div className={styles.mainBox}>
          <div className={styles.lineBreak}></div>
          <div className={styles.bottomBox}>
            <div>
              <h3 className={styles.heading}>Principal Architect:</h3>
              <div className={styles.linkItem} >Alok Shetty </div>
            </div>
            <div>
              <h3 className={styles.heading}>Senior Architects:</h3>
              <div className={styles.linkItem}>Nikash Yacoob</div>
              <div className={styles.linkItem}>Akash Fogla</div>
              <div className={styles.linkItem}>Dhanush Gowda</div>
              <div className={styles.linkItem}>Hitha Kamal</div>
              <div className={styles.linkItem}>Poorvik Patel</div>
              <div className={styles.linkItem}>Srividya Muralidhar</div>
            </div>

            <div>
              <h3 className={styles.heading}>Project Architects:</h3>
              <div className={styles.linkItem}>Shikha Das</div>
              <div className={styles.linkItem}>Adit Poonia</div>
              <div className={styles.linkItem}>Prajwal Amin</div>
              <div className={styles.linkItem}>Piyush Kanted</div>
              <div className={styles.linkItem}>Avinash Agrawal</div>
              <div className={styles.linkItem}>Abdul Rehman</div>
              <div className={styles.linkItem}>Arya Sunil</div>
              <div className={styles.linkItem}>Rosy Jose</div>
            </div>

            <div>
              <h3 className={styles.heading}>Junior Architects:</h3>
              <div className={styles.linkItem}>Ashish Supakar</div>
              <div className={styles.linkItem}>Abirami P</div>
              <div className={styles.linkItem}>Robin Mohapatra</div>
              <div className={styles.linkItem}>Sai Siddarth</div>
              <div className={styles.linkItem}>Fazil Irfan</div>
              <div className={styles.linkItem}>Rishab De</div>
              <div className={styles.linkItem}>Devashish Ojha</div>
              <div className={styles.linkItem}>Gandharv Marwah</div>
              <div className={styles.linkItem}>Titiksha Dhiman</div>
              <div className={styles.linkItem}>Sharun Christo</div>
              <div className={styles.linkItem}>Harimadh S</div>
            </div>

            <div>
              <h3 className={styles.heading}>Fresher Architects:</h3>
              <div className={styles.linkItem}>Mandira Pai</div>
              <div className={styles.linkItem}>Preeti Mudaliar</div>
              <div className={styles.linkItem}>Neha Vedaprakash</div>
              <div className={styles.linkItem}>Sahana Kumar</div>
              <div className={styles.linkItem}>Garima Dubey</div>
              <div className={styles.linkItem}>Teresa Joseph</div>
            </div>

            <div>
              <h3 className={styles.heading}>3D Artists:</h3>
              <span className={styles.linkItem}>Allen Daniel</span>
              <span className={styles.linkItem}>Suraju Sugathan</span>
              <span className={styles.linkItem}>Sagar LM</span>
              <span className={styles.linkItem}>Razlan Mohammad</span>
            </div>

            <div>
              <h3 className={styles.heading}>Administration:</h3>
              <div className={styles.linkItem}>Gopinath Mohanraj - Head of Operations</div>
              <div className={styles.linkItem}>Saravana J - Human Resources</div>
              <div className={styles.linkItem}>Raghu A - Accounts</div>
              <div className={styles.linkItem}>Ananda K - Accounts</div>
              <div className={styles.linkItem}>Bharat Kumar - Executive Assistant</div>
              <div className={styles.linkItem}>Sathish - Office Assistant</div>
              <div className={styles.linkItem}>Chittaranjan - Office Assistant</div>
              <div className={styles.linkItem}>Ashok Kumar - Housekeeping</div>
            </div>
          </div>


        </div>
        <div className={styles.mainBox}>
          <div className={styles.lineBreak}></div>
          <div className={styles.bottomBox}>
            <div>
              <h3 className={styles.heading}>QS & Estimation:</h3>
              <p className={styles.linkItem} >Alok Shetty </p>
            </div>
            <div>
              <h3 className={styles.heading}>Civil & PMC:</h3>
              <span className={styles.linkItem}>Nikash Yacoob</span>
              <span className={styles.linkItem}>Akash Fogla</span>
              <span className={styles.linkItem}>Dhanush Gowda</span>
              <span className={styles.linkItem}>Hitha Kamal</span>
              <span className={styles.linkItem}>Poorvik Patel</span>
              <span className={styles.linkItem}>Srividya Muralidhar</span>
            </div>

            <div>
              <h3 className={styles.heading}>BIM + Drafting:</h3>
              <span className={styles.linkItem}>Nikash Yacoob</span>
              <span className={styles.linkItem}>Akash Fogla</span>
              <span className={styles.linkItem}>Dhanush Gowda</span>
              <span className={styles.linkItem}>Hitha Kamal</span>
              <span className={styles.linkItem}>Poorvik Patel</span>
              <span className={styles.linkItem}>Srividya Muralidhar</span>

            </div>

            <div>
              <h3 className={styles.heading}>Interns:</h3>
              <span className={styles.linkItem}>Nikash Yacoob</span>
              <span className={styles.linkItem}>Akash Fogla</span>
              <span className={styles.linkItem}>Dhanush Gowda</span>
              <span className={styles.linkItem}>Hitha Kamal</span>
              <span className={styles.linkItem}>Poorvik Patel</span>
              <span className={styles.linkItem}>Srividya Muralidhar</span>
            </div>

            <div>
              <h3 className={styles.heading}>Document Controller:</h3>
              <p className={styles.linkItem}>Why Choose TikBits</p>
            </div>

            <div>
              <h3 className={styles.heading}>Transport:</h3>
              <p className={styles.linkItem}>Why Choose TikBits</p>
            </div>
          </div>
        </div>


        <div className={styles.mainBox}>
          <div className={styles.lineBreak}></div>
          <div className={styles.bottomBox}>
            <div>
              <h3 className={styles.heading}>Bhumi Alumni:</h3>
              <h3 className={styles.heading}>Architects, Engineers & Artists:</h3>
              <p className={styles.linkItem} >Alok Shetty </p>
            </div>
            <div>
              <h3 className={styles.heading}>Bhumi Alumni:</h3>
              <h3 className={styles.heading}>Interns:</h3>
              <span className={styles.linkItem}>Nikash Yacoob</span>
              <span className={styles.linkItem}>Akash Fogla</span>
              <span className={styles.linkItem}>Dhanush Gowda</span>
              <span className={styles.linkItem}>Hitha Kamal</span>
              <span className={styles.linkItem}>Poorvik Patel</span>
              <span className={styles.linkItem}>Srividya Muralidhar</span>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
export default Practice