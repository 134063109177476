import React from "react"
import Navmenu from "../../components/Navmenu/Navmenu";
import styles from "./AdarshPalm.module.css";
import { useState } from "react";
import { ImageOverlay } from 'react-image-overlay-effect'
import { Link } from "react-router-dom"
import SlideAdarsh from "../SlideAdarsh/SlideAdarsh"

const showcase = [
  {
    id: "0",
    heading: <img
      src="images/projectone.jpg"
      style={{
        width: "100%", height: "100%", padding: 0,
      }}
      alt="Computer" /> },
  {
    id: "0",
    heading:<img
    src="images/projecttwo.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
 

 {
    id: "0",
    heading:<img
    src="images/projectthree.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
  {
    id: "0",
    heading:<img
    src="images/projectfour.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
 
 {
    id: "0",
    heading:<img
    src="images/projectfive.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},

{
    id: "0",
    heading:<img
    src="images/projectsix.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
   {
    id: "0",
    heading:<img
    src="images/projectseven.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
   {
    id: "0",
    heading:<img
    src="images/projecteight.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="Computer"
/>},
  
]

const Home = () => {
  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  return (
    <div >
      <Navmenu />
      <div >
      <div className={styles.heads}>ADARSH PALM RETREAT</div> 
      <div className={styles.sliding}><SlideAdarsh/></div>
        <div className={styles.showGrid}>
          {showcase.slice(0, visible).map((item, index) => (
            <div key={index} className={styles.showCard}>
           {item.heading}  
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {visible < showcase.length &&
          <button className={styles.showmore} onClick={showMoreItems} >Show More</button>
        }
      </div>
    </div>
  )
}
export default Home