import React from "react"
import Carousel from 'react-bootstrap/Carousel';

function Prestigeslide() {
  return (
    <Carousel>
      <Carousel.Item>
       
      <img
         src="images/Prestige/prestigeflatbedroom.jpg"
          style={{
            width: "100%", height: "100%", padding: 0,
          }}
          alt="prestigeflatbedroom" /> 
      
      </Carousel.Item>
      <Carousel.Item>
      <img
        src="images/Prestige/prestigeflatbedroomtwo.jpg"
      style={{
        width: "100%", height: "50%", padding: 0,
      }}
      alt="prestigeflatbedroomtwo" />

       
      </Carousel.Item>
      <Carousel.Item>
      <img
    src="images/Prestige/prestigeflatdining.jpg"
    style={{
        width: "100%",
        height: "100%",
        padding: 0,
    }}
    alt="prestigeflatdining"
/>
        
      </Carousel.Item>
      <Carousel.Item>
       
       <img
         src="images/Prestige/prestigeflatdoor.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="prestigeflatdoor" /> 

       </Carousel.Item>
       <Carousel.Item>
       
       <img
         src="images/Prestige/prestigeflathall.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="prestigeflathall" /> 
         
       </Carousel.Item>

       <Carousel.Item>
       
       <img
           src="images/Prestige/prestigeflathalltwo.jpg"
           style={{
             width: "100%", height: "100%", padding: 0,
           }}
           alt="prestigeflathalltwo" /> 
         
       </Carousel.Item>

      
    </Carousel>
  );
}

export default Prestigeslide;